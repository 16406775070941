<template>

<div class="columns">

	<div class="columns-item">Very important</div>
	<div class="columns-item">Important</div>
	<div class="columns-item">Not important</div>
	<div class="columns-item">Not at all important</div>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.columns {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-top: 40px;
	padding-bottom: 20px;
	border-bottom: 1px solid #707070;
}

.is-mobile .columns {
	margin-top: 0px;
	width: 100%;
	padding-bottom: 10px;
	border-bottom-width: 0px;
}

.columns-item {
	width: 150px;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 1.8px;
	color: #2A273E;
	text-transform: uppercase;
	text-align: center;
}

.is-mobile .columns-item {
	width: 25%;
	font-size: 10px;
	letter-spacing: 0px;
	line-height: 14px;
}

</style>
